import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/auth';

const Login = () => {

    const { login, error, setError } = useContext(AuthContext);

    // Estados para armazenar os valores do e-mail e senha
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        login(email, password);
    };

    const handlerKeyDownEmail = (e) => {
        setError('');
        var k = e.keyCode;
        if (k === 13) {
            e.preventDefault();
            document.getElementById('password').focus();
        }
    }

    const displayError = !error === true ? 'alert alert-danger visually-hidden' : 'alert alert-danger';

    return (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-primary">
            <div className="login-container p-4 bg-white rounded shadow" style={{ maxWidth: '400px', width: '100%' }}>
                <h2 className="text-center mb-4">FAQ Login</h2>
                <div className={displayError} role="alert">
                    {error}
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">E-mail</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Insira o e-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => handlerKeyDownEmail(e)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Senha</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="**********"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Login</button>
                </form>
                <h6 className="text-center mt-3" style={{cursor: "pointer"}}><a style={{textDecoration: "none"}} href='https://www.easydash.easysoftsistemas.com.br/cadastro?origem=faq'>Faça seu cadastro aqui!</a></h6>
            </div>
        </div>
    );
}

export default Login;