import React, { useContext, useEffect, useState } from "react";
import { getFAQ } from "../../services/api";
import { useNavigate } from 'react-router-dom';
import './style.css';
import defaultImage from "../../assets/images/default.jpg";
import { AuthContext } from '../../contexts/auth';

const ListFAQ = () => {
    const [faqData, setFaqData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar o loading
    const navigate = useNavigate();

    const { nivel, logout } = useContext(AuthContext);

    const getFAQF = async (page = 1, titulo = '') => {
        setIsLoading(true); // Inicia o loading
        setCurrentPage(page);
        try {
            const response = await getFAQ(page, titulo); // Enviar o número da página para a API
            setFaqData(response.data.items); // Supondo que a resposta tenha uma propriedade `items`
            setTotalPages(response.data.totalPages); // Supondo que a resposta tenha uma propriedade `totalPages`
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false); // Finaliza o loading
        }
    }

    useEffect(() => {
        getFAQF(currentPage, searchQuery);
    }, [currentPage]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const keyDownSearch = (event) => {
        if (event.keyCode === 13) {
            getFAQF(1, searchQuery)
        }
    }

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div className="container-fluid mt-4">
            <div className="row">
                <div className="col-md-9 col-sm-12 mb-3">
                    <input
                        placeholder="Pesquisar"
                        className="form-control"
                        value={searchQuery}
                        onChange={handleSearch}
                        onKeyDown={(e) => keyDownSearch(e)}
                    />
                </div>
                <div className="col-md-3 col-sm-12 justify-content-md-end" style={{ display: "inline-flex" }}>
                    <button className={`btn btn-success w-${nivel === 1 ? '50' : '100'} me-3 mb-3`}
                        onClick={(e) => getFAQF(1, searchQuery)}>Pesquisar</button>
                    {nivel === 1 && (
                        <button className="btn btn-primary w-50 me-3 mb-3"
                            onClick={(e) => navigate('/admin-list-faq')}>Admin</button>
                    )}
                    <button className={`btn btn-danger w-${nivel === 1 ? '50' : '100'} me-3 mb-3`}
                        onClick={(e) => logout()}>Sair</button>

                </div>
            </div>

            {isLoading ? ( // Se estiver carregando, exibe a tela de loading
                <div className="row justify-content-center">
                    <div className="col-2">
                        <div className="loading-spinner">Carregando...</div>
                    </div>
                </div>
            ) : ( // Caso contrário, exibe os dados
                <>
                    <div className="row">
                        {faqData.map((row) => (
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4" key={row.id}>
                                <div className="card shadow-sm h-100" onClick={() => navigate(`/faq/${row.id}`)}>
                                    <img
                                        src={row.img || defaultImage}
                                        alt={row.titulo}
                                        className="card-img-top"
                                        style={{ objectFit: 'contain', height: '200px' }}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">{row.titulo} - <i>{new Date(row.data).toLocaleDateString()}</i></h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row justify-content-center mb-5">
                        <div className="col-2">
                            <div className="pagination">
                                <button className="btn btn-secondary me-3" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                    Anterior
                                </button>
                                <span className="mt-1">Página {currentPage} de {totalPages}</span>
                                <button className="btn btn-secondary ms-3" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                    Próxima
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default ListFAQ;
