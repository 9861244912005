import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ListFAQ from './pages/list-faq';
import FAQ from './pages/faq';
import AdminListFAQ from './pages/admin-list-faq';
import AdminFAQ from './pages/admin-faq';
import { AuthContext, AuthProvider } from './contexts/auth';
import Login from './pages/login';
import PublicFAQ from './pages/faq-public';

function Rotas() {

    const Private = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);
        if (loading) {
            return <p>Carregando....</p>;
        }
        if (!authenticated) {
            return <Navigate to="/login" />;
        }
        return children;
    }

    const Public = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);
        if (loading) {
            return <p>Carregando....</p>;
        }
        // if (authenticated) {
        //     return <Navigate to="/" />;
        // }
        return children;
    }

    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route exact path='/login' element={<Public><Login /></Public>} />
                    <Route exact path='/public/faq/:id' element={<Public><PublicFAQ /></Public>} />

                    <Route exact path='/' element={<Private><ListFAQ /></Private>} />
                    <Route exact path='/admin-add-faq' element={<Private><AdminFAQ mode="A" /></Private>} />
                    <Route exact path='/admin-edit-faq/:id' element={<Private><AdminFAQ mode="E" /></Private>} />
                    <Route exact path='/admin-list-faq' element={<Private><AdminListFAQ /></Private>} />
                    <Route exact path='/list-faq' element={<Private><ListFAQ /></Private>} />
                    <Route exact path='/faq/:id' element={<Private><FAQ /></Private>} />
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default Rotas;