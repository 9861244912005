import axios from "axios";

export const api = axios.create({
    // baseURL : 'http://localhost:9007',
    //baseURL : 'http://192.168.3.135:9007',
    //baseURL   : 'http://177.39.17.232:9007',
    baseURL : 'https://apifaq.easysoftsistemas.com.br',
});

export const createSession = async (email, password) => {
    return api.post('/login', { email, password });
}

export const getFAQ = async (page, titulo) => {
    return api.get('/faq', { params: { page, titulo } });
}

export const getFAQId = async (id) => {
    return api.get('/faq/' + id);
}

export const getFAQIdPublic = async (id) => {
    return api.get('/public/faq/' + id);
}

export const getNiveis = async () => {
    return api.get('/nivel/');
}

export const deleteFAQ = async (id) => {
    return api.delete('/faq/' + id);
}

export const deleteFAQItem = async (id) => {
    return api.delete('/faq/item/' + id);
}

export const postFAq = async (faqData) => {
    return api.post('/faq/', faqData);
}

export const postFAqItem = async (faqData) => {
    return api.post('/faq/item', faqData);
}