import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { deleteFAQItem, getFAQId, getNiveis, postFAq, postFAqItem } from "../../services/api";
import Swal from "sweetalert2";
import { AuthContext } from "../../contexts/auth";
// import imageCompression from 'browser-image-compression';

const AdminFAQ = ({ mode }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { nivel, logout } = useContext(AuthContext);
    const [niveis, setNiveis] = useState([]);
    const [nivelC, setNivelC] = useState(0);
    const [titulo, setTitulo] = useState("");
    const [items, setItems] = useState([{ texto: "", seq: 1, img: "" }]);
    const [tituloImg, setTituloImg] = useState("");


    useEffect(() => {
        if (mode === 'E') {
            getFAQIdF();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, id]);

    useEffect(() => {
        if (!nivel === 1) {
            logout();
        }

        getNiveisF();
    }, []);

    const getNiveisF = async () => {
        try {
            const response = await getNiveis();
            if (response.status === 200) {
                setNiveis(response.data);
            }
            else {
                alert('Erro interno: ' + response.data);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFAQIdF = async () => {
        try {
            const response = await getFAQId(id);
            setTitulo(response.data.titulo);
            setItems(response.data.items);
            setTituloImg(response.data.img);
            setNivelC(response.data.nivel);
        } catch (err) {
            console.error(err);
        }
    }

    const handletituloChange = (e) => {
        setTitulo(e.target.value);
    };

    const handleItemChange = (index, e) => {
        const { name, value } = e.target;
        const newItems = [...items];
        newItems[index][name] = name === "seq" ? parseInt(value) : value;
        setItems(newItems);
    };

    const addItem = () => {
        const maxSeq = items.reduce((max, item) => Math.max(max, item.seq), 0);
        setItems([...items, { texto: "", seq: maxSeq + 1, img: "" }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const faqData = {
            id,
            titulo,
            img: tituloImg,
            nivel: nivelC
        };
        const response = await postFAQF(faqData);
        if (response && response.status === 200) {
            if (mode === 'E') {
                await postItems(0);
            }
            else {
                await postItems(response.data.id);
            }
        }
    };

    const postFAQF = async (data) => {
        try {
            const response = await postFAq(data);
            if (response.status === 200) {
                return response;
            } else {
                Swal.fire({
                    title: response.data,
                    icon: "error"
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    const postItems = async (faqid) => {
        if (faqid === 0) {
            faqid = id;
        }
        // eslint-disable-next-line no-unused-vars
        const r = await deleteFAQItem(faqid);
        for (const item of items) {
            // const compressedItemImg = item.img ? await compressImage(item.img) : "";
            const itemData = {
                faqId: faqid,
                texto: item.texto,
                seq: item.seq,
                img: item.img
            };
            try {
                const response = await postFAqItem(itemData); // Supondo que você tenha uma rota API para postar itens individualmente
                if (response.status !== 200) {
                    Swal.fire({
                        title: `Erro ao enviar item ${item.seq}`,
                        icon: "error"
                    });
                }
            } catch (err) {
                console.error(`Erro ao enviar item ${item.seq}`, err);
            }
        }
        navigate('/admin-list-faq/');
    };

    const removeItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };

    const handlePaste = async (index, e) => {
        const clipboardItems = e.clipboardData.items;
        for (const item of clipboardItems) {
            if (item.type.startsWith('image/')) {
                const file = item.getAsFile();
                const reader = new FileReader();
                reader.onload = (event) => {
                    const newItems = [...items];
                    newItems[index].img = event.target.result;
                    setItems(newItems);
                };
                reader.readAsDataURL(file);
                e.preventDefault();
                break;
            }
        }
    };

    const handleTituloPaste = async (e) => {
        const clipboardItems = e.clipboardData.items;
        for (const item of clipboardItems) {
            if (item.type.startsWith('image/')) {
                const file = item.getAsFile();
                const reader = new FileReader();
                reader.onload = (event) => {
                    setTituloImg(event.target.result);
                };
                reader.readAsDataURL(file);
                e.preventDefault();
                break;
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            {mode === 'E' ? 'Editar FAQ' : 'Adicionar FAQ'}
                                        </h6>
                                    </div>
                                    <div className="col-auto">
                                        <button type="submit" className="btn btn-success me-2">Salvar</button>
                                        <button onClick={() => navigate('/admin-list-faq/')} className="btn btn-secondary">Cancelar</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="row d-flex">
                                    <div className="col-8">
                                        <div className="form-group">
                                            <label htmlFor="titulo">Título</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="titulo"
                                                value={titulo}
                                                onChange={handletituloChange}
                                                onPaste={handleTituloPaste} // Adicionar suporte para colar imagem do título
                                            />
                                            {tituloImg && (
                                                <div className="mt-2">
                                                    <img src={tituloImg} alt="Título" className="img-thumbnail" style={{ maxHeight: "150px ", width: 'auto' }} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label htmlFor="nivel">Nível acesso</label>
                                            <select
                                                className="form-select"
                                                id="nivel"
                                                value={nivelC}
                                                onChange={(e) => setNivelC(e.target.value)}>
                                                {niveis.map((nivel) => (
                                                    <option key={nivel.id} value={nivel.id}>
                                                        {nivel.descricao}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {items.map((item, index) => (
                                        <div key={index} className="form-group">
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="number"
                                                    className="form-control mb-2 mr-2 w-25"
                                                    placeholder="Sequência"
                                                    name="seq"
                                                    value={item.seq}
                                                    onChange={(e) => handleItemChange(index, e)}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control mb-2 mr-2"
                                                    placeholder="Descrição"
                                                    name="texto"
                                                    value={item.texto}
                                                    onChange={(e) => handleItemChange(index, e)}
                                                    onPaste={(e) => handlePaste(index, e)}
                                                />
                                                <button type="button" className="btn btn-danger mb-2" onClick={() => removeItem(index)}>
                                                    Remover
                                                </button>
                                            </div>
                                            {item.img && (
                                                <div className="mb-2">
                                                    <img src={item.img} alt={`Item ${item.seq}`} className="img-thumbnail" style={{ maxHeight: "200px", width: 'auto' }} />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="row d-flex">
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary me-2" onClick={addItem}>Adicionar Item</button>
                                        <button type="submit" className="btn btn-success">Salvar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form >
    );
};

export default AdminFAQ;
