import React, { useEffect, useState } from "react";
import { getFAQIdPublic } from "../../services/api";
import { useParams } from "react-router-dom";

const PublicFAQ = () => {
    const { id } = useParams();
    const [faqData, setFaqData] = useState({ items: [] });
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const getFAQIdF = async () => {
        try {
            const response = await getFAQIdPublic(id);
            setFaqData(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getFAQIdF();
    }, [id]);

    const handlePrev = () => {
        if (currentIndex > 0 && !isTransitioning) {
            setIsTransitioning(true);
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < faqData.items.length - 1 && !isTransitioning) {
            setIsTransitioning(true);
            setCurrentIndex(currentIndex + 1);
        }
    };

    useEffect(() => {
        const handleTransitionEnd = () => {
            setIsTransitioning(false);
        };

        const carouselElement = document.querySelector('.carousel-inner');
        if (carouselElement) {
            carouselElement.addEventListener('transitionend', handleTransitionEnd);
        }

        return () => {
            if (carouselElement) {
                carouselElement.removeEventListener('transitionend', handleTransitionEnd);
            }
        };
    }, [currentIndex]);

    return (
        <div className="container-fluid p-0">
            <div className="fixed-title text-center">
                <h5>{faqData.titulo}</h5>
            </div>
            <div className="carousel-container">
                <div id="carouselExample" className="carousel slide">
                    <div className="carousel-inner">
                        {faqData.items.map((item, index) => (
                            <div className={`carousel-item ${index === currentIndex ? 'active' : ''}`} key={index}>
                                {item.img && (
                                    <img
                                        src={item.img}
                                        alt={`Item ${index}`}
                                        className="img-thumbnail"
                                        style={{ maxHeight: 'calc(100vh - 150px)', width: 'auto', objectFit: 'contain' }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Contador de imagens */}
                    <div className="carousel-counter">
                        {currentIndex + 1} de {faqData.items.length}
                    </div>
                </div>
                {/* Caption fixado na parte inferior da tela */}

                <button
                    className="carousel-control-prev fixed-button left"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="prev"
                    onClick={handlePrev}
                    disabled={currentIndex === 0 || isTransitioning}
                >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next fixed-button"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="next"
                    onClick={handleNext}
                    disabled={currentIndex === faqData.items.length - 1 || isTransitioning}
                >

                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className="fixed-caption">
                <p>{faqData.items[currentIndex]?.texto}</p>
            </div>
        </div>
    );
};

export default PublicFAQ;
