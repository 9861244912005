import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, createSession } from '../services/api.jsx';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [nivel, setNivel] = useState(0);

    useEffect(() => {
        try {
            api.defaults.headers.Authorization = `Basic dGVzdHNlcnZlcjp0ZXN0c2VydmVy`;
            const recoveredUser = localStorage.getItem('user');
            const token = localStorage.getItem('token');

            if (recoveredUser) {
                setUser(JSON.parse(recoveredUser));
                api.defaults.headers.Authorization = `Bearer ${token}`;

                const tokenParts = token.split('.');
                const payload = tokenParts[1];
                const decodedPayload = atob(payload);
                const payloadObject = JSON.parse(decodedPayload);
                setNivel(payloadObject.nivel);
            }

        } catch (error) {
            logout();
        }

        setLoading(false);
    }, []);

    const login = async (email, password) => {
        try {
            const response = await createSession(email, password);

            if (response.data.user) {
                const loggedUser = response.data.user;
                const token = response.data.token;

                localStorage.setItem('user', JSON.stringify(loggedUser));
                localStorage.setItem('token', token);

                api.defaults.headers.Authorization = `Bearer ${token}`;

                setUser(loggedUser);

                const tokenParts = token.split('.');
                const payload = tokenParts[1];
                const decodedPayload = atob(payload);
                const payloadObject = JSON.parse(decodedPayload);
                setNivel(payloadObject.nivel);

                navigate("/");
            }
        }
        catch (err) {
            setError(err.response.data);
        }
    }

    const logout = () => {
        setUser(null);
        setError("");
        setNivel(0);
        api.defaults.headers.Authorization = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("active");
        api.defaults.headers.Authorization = `Basic dGVzdHNlcnZlcjp0ZXN0c2VydmVy`;
        navigate("/login");
    }

    return (
        <AuthContext.Provider value={{ authenticated: !!user, user, loading, error, login, logout, setLoading, setError, nivel }}>
            {children}
        </AuthContext.Provider>
    );
}

